<template>
  <div class="wiki-search-page">
    <wiki-header :steps="[{label: title}]"></wiki-header>
    <div class="limited_content">
      <h4>{{ title }}</h4>
      <div v-if="noResults" class="results-empty">
        Sorry, no results found.
        <get-help-cta></get-help-cta>
      </div>
      <wiki-loading v-else-if="wikiSearchLoadingState" :loading="true"></wiki-loading>
      <template v-else-if="searchRan">
        <base-pagination-meta :meta="wikiSearchMetaState"></base-pagination-meta>
        <div class="search-results">
          <wiki-search-result-item
            v-for="article in wikiSearchState"
            :key="article.slug"
            :article="article"
          ></wiki-search-result-item>
        </div>
        <base-pagination :meta="wikiSearchMetaState" @onSwitchPage="onSwitchPage"></base-pagination>
      </template>
    </div>
    <go-up-button></go-up-button>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import BasePaginationMeta from '@common/elements/base-pagination-meta';
import GoUpButton from '@common/elements/buttons/GoUpButton';
import WikiHeader from '@common/pages/wiki/WikiHeader';
import WikiLoading from '@common/pages/wiki/WikiLoading';
import wikiSearch from '@common/pages/wiki/js/wikiSearch';
import WikiSearchResultItem from '@common/pages/wiki/search/WikiSearchResultItem';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {mapGetters} from 'vuex';

import GetHelpCta from '@/components/modules/wiki/GetHelpCta';

export default {
  metaInfo() {
    return wikiSearch.getMetaInfo();
  },
  created() {
    if (this.isQueryParamsApplied) {
      this.searchRan = true;
      return;
    }
    this.parseFiltersFromQuery();
    this.init();
  },
  data() {
    return {
      searchRan: false,
    };
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        this.init();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['wikiSearchLoadingState', 'wikiSearchState', 'wikiSearchMetaState', 'wikiSearchFormQueryState']),
    keyword() {
      return this.$route.query.keyword;
    },
    title() {
      return this.keyword ? `Search Results for "${this.keyword}"` : 'Search for articles';
    },
    noResults() {
      return this.searchRan && this.wikiSearchState.length === 0;
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
    scrollToElement() {
      return this.isDesktop ? '.wiki-search-page' : '.limited_content';
    },
    isQueryParamsApplied() {
      return this.$route.query.keyword == this.wikiSearchFormQueryState;
    },
  },
  methods: {
    search(page) {
      wikiSearch.search(page, this).then(filters => {
        AnalyticsAmplitudeHandler.trackWikiSearchSubmit(filters, this.wikiSearchState.length);
      });
    },
    init() {
      wikiSearch.init(this);
    },
    onSwitchPage(page) {
      wikiSearch.onSwitchPage(page, this);
    },
    scrollToSearchResults() {
      wikiSearch.scrollToSearchResults(this);
    },
    parseFiltersFromQuery() {
      wikiSearch.parseFiltersFromQuery(this);
    },
  },
  name: 'WikiSearchPage',
  components: {
    GetHelpCta,
    WikiSearchResultItem,
    BasePagination,
    BasePaginationMeta,
    WikiHeader,
    GoUpButton,
    WikiLoading,
  },
};
</script>

<style lang="scss" scoped></style>
